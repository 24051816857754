export class PlantType {
    id: any;
    name: any;
    description: any;
    path: any;
    constructor(plant) {
        {
            this.id = plant.id;
            this.name = plant.name;
            this.description = plant.description;
            this.path = plant.path;
        }
    }
}

