import { FuseUtils } from '@fuse/utils';

export class Ubigeo {
    id: number;
    department: any;
    province: any;
    district: any;
    name: any;

    constructor(ubigeo) {
        this.id = ubigeo.id || FuseUtils.generateGUID();
        this.department = ubigeo.department || '';
        this.province = ubigeo.province || '';
        this.district = ubigeo.district || '';
        this.name = ubigeo.name || '';
    }
}

export interface Bank {
    id: any;
    name: string;
}


export class Collaborator {
    id: number | any;
    name: any;
    lastname: any;
    role_name: any;
    email: any;

    constructor(collaborator) {
      
            this.id = collaborator.id;
            this.name = collaborator.name;
            this.lastname = collaborator.lastname;
            this.role_name = collaborator.role_name;
            this.email = collaborator.email;
        
    }
}
